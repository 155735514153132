<template>
    <section class="component-donuts">
        <div class="row mx-0 justify-content-end">
            <div class="col-auto">
                <p class="text-black f-500 f-20 mb-2">
                    Mascotas
                </p>
            </div>
            <div v-if="!verOtros" class="ml-auto px-0 text-green-strong f-20 f-500" />
            <div v-else class="ml-auto px-0 text-green-strong f-20 f-500 cr-pointer" style="text-decoration:underline"  @click="verMas">
                Ver otras
            </div>
        </div>
        <div class="row mx-0">
            <div v-if="verGrafica" class="col-12 px-0">
                <echart :options="mascotas" />
            </div>
            <div v-else class="col-12 px-0 text-center">
                <img class="obj-cover" src="/img/no-imagen/no-grafica.png" />
            </div>
        </div>
        <!-- Partials -->
        <modal-ver-mas-info ref="modalVerMasInfo" />
    </section>
</template>
<script>
const colorsGender = [ "#00D9B5" /* Otros */, "#FFB2C2" /* Perros */ , "#FFD54F" /* Gatos */ ]

import Vue from 'vue'
import echarts from 'echarts'
Vue.component('echarts',echarts);

export default {
    props: {
        data: {
            type: Array,
            default: []
        },
        conjuntos: {
            type: Array,
            default: []
        },
    },
    components: {
        modalVerMasInfo: () => import("../partials/modalVerMasInfo.vue")
    },
    data(){
        return {
            verGrafica: false,
            verOtros: false,
            mascotas: {
                tooltip: {
                    show: false,
                },
                color: colorsGender,
                series: [
                    {
                        type: 'pie',
                        radius: ['40%', '70%'],
                        labelLine: {
                            show: true
                        },
                        label: {
                            formatter: (params) => {
                                let total = this.data[params.dataIndex].total;
                                return `${params.name} \n ${total}: ${params.value}%`
                            },
                            show: true,
                            fontSize: '15',
                        },
                        hoverAnimation: false,
                        data: [
                            { value: 0, name: 'Otros' },
                            { value: 0, name: 'Perros' },
                            { value: 0, name: 'Gatos' },
                        ]
                    }
                ]
            },
        }
    },
    watch:{
        data(){
            this.verOtros = false;
            this.mapearData();
        },
    },

    mounted(){
        this.mapearData();
    },

    methods: {
        mapearData(){
            if(this.data.length < 1)return this.verGrafica = false;

            let info = this.data;

            this.mascotas.series[0].data[0].value = `${info[0].porcentaje}`;
            this.mascotas.series[0].data[0].name = 'Otros';

            this.mascotas.series[0].data[1].value = `${info[1].porcentaje}`;
            this.mascotas.series[0].data[1].name = 'Perros';

            this.mascotas.series[0].data[2].value = `${info[2].porcentaje}`;
            this.mascotas.series[0].data[2].name = 'Gatos';

            this.verGrafica = true;

            if(info[0].porcentaje > 0){
                this.verOtros = true;
            }
        },

        verMas(){
            if(this.data.filter((item) => item.tipo === 'Otros').length < 1){
                return;
            }
            this.$refs.modalVerMasInfo.toggle(this.conjuntos)
        },
    },
}
</script>
